import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"

import {  graphql } from "gatsby"
import lazySizes from 'lazysizes'

import "../styles/style.scss"

class Archive extends Component {

    state = {
        previewIndex: null
    }

    componentDidMount() {

        try {

            require('lazysizes/plugins/attrchange/ls.attrchange')
            require('lazysizes/plugins/unveilhooks/ls.unveilhooks.js')

            const UIkit = require("uikit/dist/js/uikit")

            UIkit.lightbox('.polygonDynamicsList', {
                animation: 'fade',
                videoAutoplay: true,
                velocity: 10,
                preload: 0,
                container: 'main'
            });

        } catch (e) {
            console.error(e)
        }

    }

    mouseEnter = (index) => {
        this.setState({ previewIndex: index })
    }

    mouseLeave = () => {
        this.setState({ previewIndex: null })
    }


    render() {

        const data = this.props.data?.wpgraphql?.pages?.nodes[0]

        return (

        <Layout>

            <SEO title="Polygon Dynamics"/>
            <div className="polygonDynamicsList">


                {data && data.acfDocs.sectionProject.map(( section, index ) => (
                    <div className="polygonDynamicsListGroup" key={index} >

                        <h3 className="polygonDynamicsListTitle">Ref . {section.legend}</h3>
                        <div className="polygonDynamicsListRow">

                            {section.sectionProjectContent && section.sectionProjectContent.map(( media, media_index ) => (



                               media.image ?
                                <div key={`section_project_content_${media_index}-${index}`}>
                                     <a
                                        className="polygonDynamicsListThumbnail lazyloadState lazyload"
                                        style={{backgroundImage: `url(${media.image.xsmall})` }} data-bg={media.image.medium}
                                        href={media.image.xlarge}
                                        data-caption={section.texte}
                                        onMouseEnter={() => this.mouseEnter(`${index}-${media_index}`)}
                                        onMouseLeave={() => this.mouseLeave()} >
                                    </a>
                                     <div className={`polygonDynamicsListImagePreview ${this.state.previewIndex === `${index}-${media_index}` ? 'active' : ''}`}>
                                            <img src={media.image.medium} alt=""></img>
                                     </div>
                                </div>
                               :


                                     <div key={`section_project_content_${media_index}-${index}`}>
                                          <a
                                              className="polygonDynamicsListThumbnail lazyloadState lazyload"
                                              style={{backgroundImage: `url(${media.videoposter.xsmall})` }} data-bg={media.videoposter.medium}
                                             href={media.video.mediaItemUrl} data-caption="Video" data-poster={media.videoposter.medium} data-caption={section.texte}
                                             onMouseEnter={() => this.mouseEnter(`${index}-${media_index}`)}
                                             onMouseLeave={() => this.mouseLeave()}>
                                         </a>
                                          <div className={`polygonDynamicsListImagePreview ${this.state.previewIndex === `${index}-${media_index}` ? 'active' : ''}`}>
                                              <img src={media.videoposter.medium} alt=""></img>
                                          </div>
                                     </div>


                            ))}
                    </div>
                    </div>
                ))}

            </div>

        </Layout>

        )
    }
}

export default Archive


export const archiveQuery = graphql`
query {
  wpgraphql {
    pages(first: 1, where:{ title: "Home page"} ) {
  nodes {
    title
    content
    acfDocs {
        forme3d {
          mediaItemUrl
        }
        sectionIntroduction {
            title
            subtitle
        }
        sectionProject {
            title
            reference
            texte
            legend
            modelviewerenvironmentimage{
                sourceUrl
            }
            sectionProjectContent {
                image {
                  sourceUrl
                  xsmall: sourceUrl(size: XSCOVER)
                  medium: sourceUrl(size: MCOVER)
                  xlarge: sourceUrl(size: XLCOVER)
                }
                videoposter{
                    sourceUrl
                    xsmall: sourceUrl(size: XSCOVER)
                    medium: sourceUrl(size: MCOVER)
                }
                video {
                    mediaItemUrl
                }
                size
            }
        }
      }
    }
  }
}
}
`
